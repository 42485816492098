<script>
import { MuiTable } from '@emobg/vue-base';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { AUDIT_ENTITIES } from '@domains/Shared/constants/audit.const';
import { contentCells } from './config/contentCells';

export default {
  name: 'UserAuditList',
  components: {
    MuiTable,
  },
  props: {
    isRelated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapState(DOMAINS_MODEL.crm.users, {
      user: state => state.detail.data,
    }),
    ...mapState(DOMAINS_MODEL.shared.audit, {
      userAuditData: state => {
        const auditData = state.data;

        auditData.forEach((data, index) => {
          const { valueAfter } = data;

          if (valueAfter?.promoDetails) {
            state.data[index].valueAfter = valueAfter.promoDetails;
          }
        });

        return state.data;
      },

      isUserAuditDataLoading: state => state.isLoading,
    }),
    title() {
      return this.isRelated ? 'User related audits' : 'User audits';
    },
    auditsContentCells() {
      return contentCells(this.operatorTimezone);
    },
  },
  watch: {
    isRelated: {
      async handler() {
        const userAuditQuery = this.isRelated
          ? { secondaryEntity: AUDIT_ENTITIES.user, secondaryId: this.user.id }
          : { primaryEntity: AUDIT_ENTITIES.user, primaryId: this.user.id };
        await this.getAuditData(userAuditQuery);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.shared.audit, [
      'getAuditData',
    ]),
  },
};
</script>
<template>
  <div class="UserAuditList">
    <h1 class="mb-2">
      {{ title }}
    </h1>

    <ui-card>
      <MuiTable
        :data-set="userAuditData"
        :content-cells="auditsContentCells"
        :is-loading="isUserAuditDataLoading"
        :no-data-label="FALLBACK_MESSAGE.dash"
        data-test-id="list"
      />
    </ui-card>
  </div>
</template>
